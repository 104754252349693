<div class="container-fluid">
    <div *ngIf="proficiency" class="row mt-3 m-0">
        <div class="system-head pt-2 col-md-12">Proficiency by Grade</div>
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table tablbluees text-center bg-white table-bordered">
                    <thead>
                        <tr>
                            <th>Grade </th>
                            <th>Reading</th>
                            <th>Math</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let element of tableData">
                            <td>{{element.grade}}</td>
                            <td>{{element.read}}%</td>
                            <td>{{element.math}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="changeInNCE" class="row mt-3 m-0">
        <div class="system-head pt-2 col-md-12">Avg. Change in NCE</div>
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table tablbluees text-center bg-white table-bordered">
                    <thead>
                        <tr>
                            <th>Grade </th>
                            <th>Reading</th>
                            <th>Math</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let element of tableData">
                            <td>{{element.grade}}</td>
                            <td [ngClass]="element.read > 0 ? 'bg-liggreen' : '' ">{{element.read}}</td>
                            <td [ngClass]="element.math > 0 ? 'bg-liggreen' : '' ">{{element.math}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="col-md-2 text-right offset-md-10">
            <button type="button" class="btn btn-primary">Back</button>
        </div> -->
    </div>

    <div *ngIf="ark" class="row mt-3 m-0">
        <div class="system-head pt-2 col-md-12">ARK Student Score Breakdown by Grade</div>
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table tablbluees text-center bg-white table-bordered">
                    <thead>
                        <tr>
                            <th>Grade </th>
                            <th>Low</th>
                            <th>Medium</th>
                            <th>High</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let element of tableData">
                            <td>{{element.grade}}</td>
                            <td class="bg-red">{{element.low}}%</td>
                            <td class="bg-orange">{{element.med}}%</td>
                            <td class="bg-green">{{element.high}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="col-md-2 text-right offset-md-10">
            <button type="button" class="btn btn-primary">Back</button>
        </div> -->
    </div>

    <div *ngIf="enrollment" class="row mt-3 m-0">
        <div class="system-head pt-2 col-md-12">Current Enrollment by Grade</div>
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table tablbluees text-center bg-white table-bordered">
                    <thead>
                        <tr>
                            <th>Grade </th>
                            <th>Student Count</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let element of tableData">
                            <td>{{element.grade}}</td>
                            <td>{{element.count}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="col-md-2 text-right offset-md-10">
            <button type="button" class="btn btn-primary">Back</button>
        </div> -->
    </div>

    <div class="row mt-3 m-0">
        <div class="col-md-2 text-right offset-md-10">
            <button type="button" class="btn btn-primary" [routerLink]="['/dashboard']">Back</button>
        </div>
    </div>
</div>