import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service'
import { AjaxService } from '../services/ajax.service';


export interface AcademicYear {
  id : Number;
  year : String;
}

@Component({
  selector: 'app-academic-year',
  templateUrl: './academic-year.component.html',
  styleUrls: ['./academic-year.component.scss']
})

export class AcademicYearComponent implements OnInit {

  acdemicYearId: number | null = 1;
  academicYears: AcademicYear[] = [];

  constructor(private _userService : UserService, private _ajaxService : AjaxService) { }

  async ngOnInit(): Promise<void> {
    await this.getAcademicYears()
  }

  async getAcademicYears() {
    this._ajaxService.get("dashboard/getAcademicYears").then(async (response: any) => {
      this.academicYears = response;
      this._userService.setAcademicYears(response) ;
      const idx = response.length;
      if (this._userService.getAcademicYearId().value != null) {
        this.acdemicYearId = this._userService.getAcademicYearId().value
      } else {
        this.acdemicYearId = response[idx - 1]['id']
        this._userService.setAcademicYearId(response[idx - 1]['id'])
      }
      // await this.getSchoolsList();
      // if (this._userService.getSchoolId().value != null) {
      //   let index = this.schools.findIndex((school: any) => school.schoolId == this._userService.getSchoolId().value);
      //   this.selectSchool(this.schools[index])
      // } else {
      //   this.selectSchool(this.schools[0]);
      // };
  
    });
  }

  selectAcademicYearId(e : any) {
    if (e) {
      this.acdemicYearId = parseInt(e.target.value);
      this._userService.setAcademicYearId(e.target.value)
      // this.loadWorkbook();
    }
  }

}
