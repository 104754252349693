<div class="container-fluid bg-colorgrey">
  <div class="row ml-0 mr-0 mt-3">
    <div class="col-md-12">
      <h1>Page not found</h1>
      <section class="error-container">
        <span class="four"><span class="screen-reader-text">4</span></span>
        <span class="zero"><span class="screen-reader-text">0</span></span>
        <span class="four"><span class="screen-reader-text">4</span></span>
      </section>
      <div class="link-container">
        <a class="more-link" routerLink="/login">Back to home</a>
      </div>
    </div>
  </div>
</div>