<div class="container-fluid">
    <div class="row m-0 pt-3">
        <div>
            <p class="system-head-adt">ANNUAL DATA TARGET</p>
            <textarea
              class="form-control py-3 adt-boxer"
              style="overflow: hidden; background-color: #e9ecef;"
              type="text"
              disabled
              placeholder="N/A"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              [value]="additionalADT[0]?.value"
              [rows]="additionalADT[0]?.row"
            ></textarea>
        </div>
        <div *ngIf="pdPlanArray.length > 0" class="col-md-12 text-right p-0 pt-3">
            <button class="btn btn-primary-myblue" (click)="enableEdit()">Edit</button>
            <button class="btn btn-primary-myblue" (click)="savePDPlanData()">Save</button>
            <div *ngIf="!editable" class="display-inline" ngbDropdown>
                <span ngbDropdownToggle class="pr-2 pdicon cursor-pointer pl-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path
                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    </svg>
                </span>

                <div class="profmenu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem class="btn btn-primary-myblack" (click)="exportToPDF()">Export to
                        PDF</button>
                </div>
            </div>
            <!-- <span class="pr-2 pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                    class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path
                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
            </span> -->
        </div>
        <div *ngIf="pdPlanArray.length === 0" class="col-md-12 text-right p-0 pt-3">
            <button class="btn btn-primary-myblue" (click)="addRow()">Add PD Plan</button>
        </div>
        <div *ngIf="pdPlanArray.length > 0" class="col-md-12 pl-1 pr-1 pt-2 brd-3" #contentToExport>
            <table class="tables tdrmtd mdlearning bg-white">
                <thead>
                    <tr>
                        <th colspan="9" class="text-center">Professional Development Calendar</th>
                    </tr>
                    <tr>
                        <th class="cdr-white">Date</th>
                        <th class="cdr-white">Title of PD</th>
                        <th class="cdr-white">Flagging
                            Indicator</th>
                        <th class="cdr-white">Description</th>
                        <th class="cdr-white">Focus Area Strand and/or
                            Instructional Practice</th>
                        <th class="cdr-white">Funding Source
                            of PD</th>
                        <th class="cdr-white">What are the principal actions to track success of the PD?</th>
                        <th class="cdr-white">Check box if this assessment directly support my ADT?</th>
                        <!-- <th class="cdr-white">Actions</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of pdPlanArray; let i = index;">
                        <td *ngIf="!editable" class="col-md-1">{{item.pdPlanDate}}</td>
                        <td *ngIf="editable" class="col-md-1">
                            <div>
                                <textarea [(ngModel)]="item.pdPlanDate" placeholder="Date"
                                    (keyup)="addData($event, i, 1)"
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>
                        <td *ngIf="!editable" class="col-md-1">{{item.pdPlanTitle}}</td>
                        <td *ngIf="editable" class="col-md-1">
                            <div>
                                <textarea [(ngModel)]="item.pdPlanTitle" placeholder="Title of PD"
                                    (keyup)="addData($event, i, 2)"
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>

                        <td *ngIf="!editable" class="col-md-1">{{item.flaggingIndicator}}</td>
                        <td *ngIf="editable" class="col-md-1">
                            <div>
                                <textarea [(ngModel)]="item.flaggingIndicator" placeholder="Flagging Indicator" 
                                    (keyup)="addData($event, i, 3)"
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>
                        <td *ngIf="!editable">{{item.PdPlanFocusArea}}</td>
                        <td *ngIf="editable" class="col-md-2">
                            <div>
                                <textarea [(ngModel)]="item.PdPlanFocusArea" placeholder="Description"
                                    (keyup)="addData($event, i, 4)"
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>
                        <td *ngIf="!editable">{{item.pdPlanDescription}}</td>
                        <td *ngIf="editable" class="col-md-2">
                            <div>
                                <textarea [(ngModel)]="item.pdPlanDescription"
                                    placeholder="Focus Area Strand and/or Instructional Practice"
                                    (keyup)="addData($event, i, 5)" 
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>
                        <td *ngIf="!editable">{{item.PdPlanFundingSource}}</td>
                        <td *ngIf="editable" class="col-md-2">
                            <div>
                                <textarea [(ngModel)]="item.PdPlanFundingSource" placeholder="Funding Source of PD"
                                    (keyup)="addData($event, i, 6)"
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>
                        <td *ngIf="!editable">{{item.pdPlanPrincipalActions}}</td>
                        <td *ngIf="editable" class="col-md-5">
                            <div>
                                <textarea [(ngModel)]="item.pdPlanPrincipalActions"
                                    placeholder="What are the principal actions to track success of the PD?"
                                    (keyup)="addData($event, i, 6)"
                                    style="resize: none; overflow-y: hidden;" class="form-control adt-boxer"></textarea>
                            </div>
                        </td>
                        <td class="text-center">
                            <input class="form-check-input" [(ngModel)]="item.adtCheck" type="checkbox" [disabled]="!editable">
                        </td>
                        <td>
                            <a title="Delete row" class="iconlinks redclor" (click)="deleteRow(mymodal, item, i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                                </svg>
                            </a>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="text-right pt-2">
                <button class="btn btn-primary-myblue" (click)="addRow()">Add row</button>
            </div>
        </div>
    </div>
</div>

<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title mb-0" id="modal-basic-title">PD Plan</h4>
        <button type="button" class="close-button" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete ?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close()">No</button>
        <button type="button" class="btn btn-primary" (click)="deletePDPlan()">Yes</button>
    </div>
</ng-template>