<div class="container-fluid">
    <div class="row m-0 mt-3">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="customrow">
                            <td class="heading"><b>Guardrail Step</b></td>
                            <td><b>Description </b></td>
                            <td><b>Description </b></td>
                            <td><b>Description </b></td>
                            <td><b>Description </b></td>
                            <td><b>Description </b></td>
                        </tr>
                        <tr class="customrow">
                            <td class="heading"><b>Flagging Indicator</b></td>
                            <td>School Attainment -Reading </td>
                            <td>School Attainment -Math </td>
                            <td>I ready growth</td>
                            <td>I ready reading</td>
                            <td>5 Essentials(Area)</td>

                        </tr>
                        <tr class="customrow">
                            <td class="heading"><b>Criteria</b></td>
                            <td>Bottom Quartile</td>
                            <td>Bottom Quartile</td>
                            <td>No points Awarded</td>
                            <td>No points Awarded</td>
                            <td>Least implementation</td>

                        </tr>
                        <tr class="customrow">
                            <td class="heading"><b>Focus Area Name</b></td>
                            <td>Reading Attainment</td>
                            <td>Math Attainment</td>

                            <td>Growth in Literacy skills</td>

                            <td>Growth in Numeracy skills/Math skills</td>
                            <td>Focus Area</td>


                        </tr>
                        <tr>
                            <td class="heading customrow"><b> Data Sources</b></td>
                            <td> i-Ready ; fluency/leveling Assessment teacher assessments</td>
                            <td> i-Ready ; Teacher assessments, etc.</td>
                            <td> i-Ready ; fluency/leveling Assessment teacher assessments</td>
                            <td> i-Ready ; fluency/leveling Assessment teacher assessments</td>
                            <td>5 Essentials, school-generated surveys supportive data (meetings,attendance,schedules)
                            </td>


                        </tr>
                        <tr>
                            <td class="heading customrow"><b>Possible Interventions</b></td>
                            <td>
                                <ol class="listdata">
                                    <li>
                                        Fully implement Danielson observations. and instructional coaching modal to
                                        <b> ensure that all teachers are observed
                                            biweekly.</b>
                                    </li>
                                    <li>
                                        Consider additional and renewed
                                        professional development on current
                                        curriculum supports for literacy . <b>Consult
                                            with RDS and OCS on viable
                                            opportunities provided centrally. </b>
                                    </li>
                                    <li>
                                        <b>Develop a year-long curriculum scope
                                            and sequence plan with teachers to
                                            identify key objectives </b> linked to
                                        standards and designated formative
                                        assessments to measure progress.

                                    </li>
                                    <li> Develop a year-long curriculum scope
                                        and sequence plan with teachers to
                                        identify key objectives linked to
                                        standards and designated formative
                                        assessments to measure progress.</li>
                                    <li>
                                        <b> Adjust instructional minutes </b> to support the increased need for
                                        additional time
                                        to
                                        teach content.
                                    </li>
                                    <li><b>Identify specific sub-skills to highlight
                                            and focus on throughout the year </b> and
                                        implement tracking of student progress
                                        on formative assessments to measure
                                        progress</li>
                                </ol>
                            </td>
                            <td>
                                <ol class="listdata">
                                    <li>
                                        Fully implement Danielson observations.
                                        and instructional coaching model <b> to
                                            ensure that all teachers are observed
                                            biweekly.</b>
                                    </li>
                                    <li>
                                        Consider additional and renewed
                                        professional development on current
                                        curriculum supports in Math.<b> Consult
                                            with RDS and OCS on viable
                                            opportunities provided centrally.</b>
                                    </li>
                                    <li>
                                        <b> Develop a year-long curriculum scope
                                            and sequence plan with teachers to
                                            identify key objectives </b> linked to
                                        standards and designated formative
                                        assessments to measure progress
                                    </li>
                                    <li>
                                        <b>Adjust instructional minutes </b>to support the increased need for additional
                                        time to
                                        teach content.
                                    </li>
                                    <li>
                                        <b> Identify specific sub-skills to highlight
                                            and focus on throughout the year </b>and
                                        implement tracking of student progress
                                        on formative assessments to measure
                                        progress
                                    </li>
                                </ol>
                            </td>
                            <td>
                                <ol class="listdata">
                                    <li>
                                        Monitor and support teacher use of
                                        formative assessments including the use
                                        of i-Ready to track student progress and
                                        encourage growth.
                                    </li>
                                    <li>
                                        Adjust instructional minutes to support
                                        the increased need for additional time to
                                        teach content.
                                    </li>
                                    <li>
                                        Fully implement Danielson observations
                                        and instructional coaching model to
                                        ensure that all teachers are observed
                                        biweekly.
                                    </li>
                                    <li>
                                        Review your registration and enrollment
                                        procedures to ensure that you are
                                        identifying all students appropriately.
                                    </li>
                                    <li>
                                        Assess current factors in students
                                        receiving access to content/skill mastery
                                        including technology and access to
                                        individualized, differentiated instruction.
                                        Ensure a formal technology and
                                        resource plan is created that supports
                                        all students
                                    </li>
                                </ol>
                            </td>
                            <td>
                                <ol class="listdata">
                                    <li>
                                        <b>Monitor and support teacher use of
                                            formative assessments including the use
                                            of i-Ready </b>to track student progress and
                                        encourage growth.
                                    </li>
                                    <li>
                                        <b>Adjust instructional minutes </b> to support
                                        the increased need for additional time to
                                        teach content.
                                    </li>
                                    <li>
                                        Fully implement Danielson observations
                                        and instructional coaching model<b> to
                                            ensure that all teachers are observed
                                            biweekly.</b>
                                    </li>
                                    <li>
                                        Review your registration and enrollment
                                        procedures to ensure that you are
                                        <b>identifying all students appropriately.</b>
                                    </li>
                                    <li>
                                        Assess current factors in students
                                        receiving access to content/skill mastery
                                        including technology and access to
                                        individualized, differentiated instruction.
                                        <b>Ensure a formal technology and
                                            resource plan is created that supports
                                            all students </b>
                                    </li>
                                </ol>

                            </td>
                            <td>
                                <ol class="listdata">
                                    <li>
                                        Review <b>professional development
                                            calendar </b> in the CSIP, and discuss/reflect
                                        on opportunities to collaborate among
                                        teachers and discuss curricular issues.
                                        <b>Identify specific time and frequency of
                                            activities for teacher collaboration and
                                            reflection.</b>
                                    </li>
                                    <li>
                                        Review student behavioral data and
                                        discuss implications among the staff to
                                        implement specific cultural changes
                                        across the school.<b> Develop grade level
                                            teams and action plans to address
                                            specific behavioral and cultural issues
                                            at the school.</b>
                                    </li>
                                    <li>
                                        <b>Address any outstanding cultural issues
                                            that stem from the 3C Survey </b> or other
                                        anecdotal information about student
                                        behavior, teacher culture and parent
                                        perception about the school
                                    </li>
                                    <li>
                                        Add and identify <b>new professional
                                            development on current curriculum
                                            supports that connect to specific
                                            measures on the 5 Essentials portion of
                                            the 3C Survey.</b>
                                    </li>
                                </ol>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>